var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      staticStyle: { "enable-background": "new 0 0 117 73" },
      attrs: {
        id: "Layer_1",
        version: "1.1",
        xmlns: "http://www.w3.org/2000/svg",
        "xmlns:xlink": "http://www.w3.org/1999/xlink",
        x: "0px",
        y: "0px",
        viewBox: "0 0 117 73",
        "xml:space": "preserve",
      },
    },
    [
      _c("g", [
        _c("polygon", {
          staticClass: "st0",
          attrs: { id: "path-1", points: "-5,-26 125,-26 125,91 -5,91 \t" },
        }),
      ]),
      _c("title", [_vm._v("Group 37")]),
      _c("desc", [_vm._v("Created with Sketch.")]),
      _c("g", { attrs: { id: "All-Errors" } }, [
        _c(
          "g",
          {
            attrs: {
              id: "Access-Denied",
              transform: "translate(-494.000000, -356.000000)",
            },
          },
          [
            _c(
              "g",
              {
                attrs: {
                  id: "Group-37",
                  transform: "translate(489.000000, 330.000000)",
                },
              },
              [
                _c("g", { attrs: { id: "Group-3" } }, [
                  _c("g", { attrs: { id: "Clip-2" } }),
                ]),
                _c("path", {
                  staticClass: "st1",
                  attrs: {
                    id: "Fill-4",
                    d: "M8.9,97.8c-1.8-0.2-2.4-0.5-1.6-0.8c5.8-2.1,32.1-2,44.8-2c16,0,32.7-0.1,47.1,0.5\n\t\t\t\tc6,0.2,19.7,0.4,22.1,1.1c6.2,1.9-37.5,2.4-44.6,2.4C65.1,99,18.9,99.1,8.9,97.8",
                  },
                }),
                _c("path", {
                  staticClass: "st1",
                  attrs: {
                    id: "Fill-6",
                    d: "M114.8,96H10.2C7.3,96,5,94,5,91.5v-0.3C5,91.1,5.1,91,5.3,91h114.4c0.2,0,0.3,0.1,0.3,0.3v0.3\n\t\t\t\tC120,94,117.7,96,114.8,96",
                  },
                }),
                _c("path", {
                  staticClass: "st1",
                  attrs: {
                    id: "Fill-8",
                    d: "M110,93V35.9c0-1-0.8-1.9-1.8-1.9H16.8c-1,0-1.8,0.8-1.8,1.9V93H110z",
                  },
                }),
                _c("path", {
                  staticClass: "st0",
                  attrs: {
                    id: "Fill-10",
                    d: "M112,91V35.8c0-1-0.8-1.8-1.8-1.8H18.8c-1,0-1.8,0.8-1.8,1.8V91H112z",
                  },
                }),
                _c("path", {
                  staticClass: "st2",
                  attrs: {
                    id: "Fill-12",
                    d: "M112,91.7v-4.9V75V60.2V46.3v-8.9c0-0.4,0-0.9,0-1.3c0-1.4-1.1-2-2.4-2h-4.7h-9.5H82.7H68.2\n\t\t\t\tH53.3H39.5H28.3h-7.2c-1.6,0-3.9-0.2-4,2.1c-0.1,1.7,0,3.4,0,5v12V68v13.8v8.7v1.2c0,0.2,0.2,0.2,0.3,\n        0.2h2.5h6.9h10.1h12.4h13.5\n\t\t\t\th13.7h12.8H100h7.8c1.2,0,2.5,0.1,3.7,0c0.1,0,0.1,0,0.2,0c0.4,0,0.5-0.5,\n        0.1-0.5h-2.5h-6.9H92.2H79.8H66.2H52.6H39.7H29h-7.8\n\t\t\t\th-2.4c-0.4,0-0.9-0.1-1.3,0c0,0-0.1,0-0.2,0l0.3,0.2v-4.8V75.3V60.6V46.7v-9.1c0-0.5,0-1,0-1.6c0-1.1,\n        0.9-1.6,1.8-1.6h4.9h9.5\n\t\t\t\th12.6h14.4h14.7h13.6h11.2h7.3c1.4,0,3.5-0.4,3.6,1.7c0.1,1.8,0,3.6,0,5.5v12v14.7V82v8.6v1.2C111.4,\n        92.1,112,92,112,91.7",
                  },
                }),
                _c("path", {
                  staticClass: "st3",
                  attrs: {
                    id: "Fill-14",
                    d: "M116.8,96H12.2C9.3,96,7,94,7,91.5v-0.3C7,91.1,7.1,91,7.3,91h114.4c0.2,0,0.3,0.1,0.3,0.3v0.3\n\t\t\t\tC122,94,119.7,96,116.8,96",
                  },
                }),
                _c("path", {
                  staticClass: "st0",
                  attrs: {
                    id: "Fill-16",
                    d: "M115.9,95H13.1C10.3,95,8,93.4,8,91.4v-0.2C8,91.1,8.1,91,8.3,91h112.4c0.2,0,0.3,0.1,0.3,0.2\n\t\t\t\tv0.2C121,93.4,118.7,95,115.9,95",
                  },
                }),
                _c("path", {
                  staticClass: "st2",
                  attrs: {
                    id: "Fill-18",
                    d: "M116.5,97.5h-3.3h-8.8H91.5H76.1H59.6H43.5H29.3H18.4h-5.9c-0.7,0-1.3,0-2-0.2\n\t\t\t\tc-1.9-0.6-3.3-2.4-3.6-4.6c0-0.2,0-1.1-0.1-1.2c-0.9-1.1-0.3,0-0.3,0c0,0,0.1,0,0.1,0c1.4-0.1,2.7,\n        0,4.1,0h9.1h12.7h15.2h16.5\n\t\t\t\th16.4h15.2h12.6h8.8h2.6c0.3,0,0.9-0.1,1.2,0c0.4,0.2,0,1.9-0.1,2.3C120.3,95.9,118.6,97.5,116.5,\n        97.5c-0.3,0-0.9,0.5-0.3,0.5\n\t\t\t\tc1.7,0,3.4-0.8,4.6-2.4c0.6-0.8,1-1.7,1.1-2.7c0.1-0.6,0.4-1.8-0.4-1.9c-0.3-0.1-0.6,0-0.9,\n        0h-5.9h-10.6h-14H74.1H57.3H41H26.6\n\t\t\t\tH15.3H8.4C8,91,7.5,91,7,91c-0.3,0-0.8,0.1-0.9,0.5c-0.3,0.7,0.1,2,0.3,2.6c0.3,0.9,0.8,1.7,1.5,\n        2.4c1.7,1.7,3.8,1.5,5.9,1.5h8.1\n\t\t\t\th12.6h15.5h16.7h16.5h14.7H109h6.3h0.8C116.5,98,117.1,97.5,116.5,97.5",
                  },
                }),
                _c("path", {
                  staticClass: "st3",
                  attrs: {
                    id: "Fill-20",
                    d: "M51,91c0.1,0.6,0.9,1,1.8,1h22.4c0.9,0,1.7-0.4,1.8-1H51z",
                  },
                }),
                _c("path", {
                  staticClass: "st2",
                  attrs: {
                    id: "Fill-22",
                    d: "M51,91.3c0.4,1.8,2,1.7,3.5,1.7h5.9H74c1.6,0,3.6,0.2,4-1.8c0-0.1-0.1-0.2-0.2-0.2h-8.9H54.7\n\t\t\t\th-3.3c-0.3,0-0.7,0.4-0.2,0.4h8.9h14.2h3.3c-0.1-0.1-0.1-0.1-0.2-0.2c-0.4,1.6-2.1,1.3-3.3,\n        1.3h-5.7H55.5h-1.9\n\t\t\t\tc-1,0-1.8-0.4-2-1.4C51.6,90.9,50.9,91,51,91.3",
                  },
                }),
                _c("polygon", {
                  staticClass: "st4",
                  attrs: {
                    id: "Fill-24",
                    points: "22,88 108,88 108,38 22,38 \t\t\t",
                  },
                }),
                _c("path", {
                  staticClass: "st2",
                  attrs: {
                    id: "Fill-26",
                    d: "M107.7,38h-8.5H78.9H54.2H33c-3.4,0-6.9-0.1-10.3,0c0,0-0.1,0-0.1,0c-0.1,0-0.4,0.1-0.4,0.2\n\t\t\t\tv4.9V55v14.3v12.3v4.1c0,0.6-0.1,1.4,0,1.9c0,0,0,0.1,0,0.1c0,0.1,0.1,0.1,0.2,0.1h8.5h20.3h24.7H97c3.4,\n        0,6.9,0.1,10.3,0\n\t\t\t\tc0,0,0.1,0,0.1,0c0.1,0,0.4-0.1,0.4-0.2v-4.9V71V56.7V44.3v-4.1c0-0.6,0.1-1.4,0-1.9c0,0,0-0.1,\n        0-0.1c0-0.3-0.6-0.1-0.6,0.1v4.9\n\t\t\t\tV55v14.3v12.3v4.1v1.3c0,0.2-0.1,0.5,0,0.7c0,0,0,0.1,0,0.1c0.1-0.1,\n        0.3-0.1,0.4-0.2h-8.5H78.9H54.2H33h-7h-2.2\n\t\t\t\tc-0.3,0-0.8-0.1-1.1,0c0,0-0.1,0-0.1,0c0.1,0,0.1,0.1,0.2,0.1v-4.9V71V56.7V44.3v-4.1v-1.3c0-0.2,0.1-0.5,\n        0-0.7c0,0,0-0.1,0-0.1\n\t\t\t\tl-0.4,0.2h8.5h20.3h24.7H97c3.4,0,6.9,0.1,10.3,0c0,0,0.1,0,0.1,0C107.7,38.4,108.2,38,107.7,38",
                  },
                }),
                _c("path", {
                  staticClass: "st3",
                  attrs: {
                    id: "Fill-27",
                    d: "M96,87l0-58.2c0-1.5-1.3-2.8-2.8-2.8L48,26L33,40.9l0,0L33,87H96z",
                  },
                }),
                _c("path", {
                  staticClass: "st0",
                  attrs: {
                    id: "Fill-28",
                    d: "M96,89l0-57.2c0-1.5-1.2-2.8-2.7-2.8l-42.7,0l-0.6,0.6l0,11.2c0,1.6-1.3,3-2.9,3l-8.9,0\n\t\t\t\tc-1.2,0-2.2,1-2.2,2.3L36,89H96z",
                  },
                }),
                _c("path", {
                  staticClass: "st2",
                  attrs: {
                    id: "Fill-29",
                    d: "M97,87.6c0-4,0-8.1,0-12.1c0-8.2,0-16.4,0-24.6c0-6.5,0-13,0-19.5c0-1.4,0.3-3.3-0.8-4.4\n\t\t\t\tC95,25.7,92.6,26,90.9,26c-7.4,0-14.7,0-22.1,0c-6.4,0-12.8,0-19.2,0c-0.4,0-1-0.1-1.4,0.1c-0.6,\n        0.3-1.1,1.1-1.6,1.5\n\t\t\t\tc-2.7,2.7-5.4,5.4-8.2,8.1c-1.3,1.3-2.6,2.6-3.8,3.8c-0.4,0.4-0.8,0.8-1.1,1.1c-0.1,0.1-0.3,0.3-0.4,\n        0.4c-0.1,0.3,0,0.8,0,1\n\t\t\t\tc0,1,0,1.9,0,2.9c0,7.5,0,15,0,22.5c0,6.4,0,12.8,0,19.1v1c0,0.1,0.2,0.1,0.3,0.1h6.3h15.1h18.2h15.7c2.5,\n        0,5.1,0.2,7.7,0\n\t\t\t\tc0,0,0.1,0,0.1,0c0.3,0,1-0.4,0.4-0.4h-6.3H75.4H57.2H41.4h-5.2h-1.6c-0.2,0-0.6-0.1-0.9,0c0,0-0.1,0-0.1,0\n\t\t\t\tc0.1,0,0.2,0.1,0.3,0.1c0-3,0-6.1,0-9.1c0-6.3,0-12.5,0-18.8c0-5.3,0-10.5,0-15.8v-2c0-0.2-0.1-0.6,\n        0-0.8c0,0.2,0.1,0,0.1-0.1\n\t\t\t\tc0.2-0.2,0.4-0.4,0.6-0.6c0.8-0.8,1.6-1.6,2.4-2.4c2.2-2.2,4.5-4.5,6.7-6.7c1.6-1.6,3.3-3.2,\n        4.8-4.8c0.1-0.1,0.2-0.2,0.3-0.3\n\t\t\t\tc-0.2,0.1-0.4,0.1-0.6,0.2c6.6,0,13.2,0,19.8,0c8.2,0,16.3,0,24.5,0c1.4,0,2.8,0.2,3.3,1.8c0.2,\n        0.6,0.2,1.3,0.2,2\n\t\t\t\tc0,2.8,0,5.6,0,8.4l0,29c0,4.3,0,8.7,0,13c0,2.3-0.1,4.6,0,6.8c0,0.1,0,0.2,0,0.3C96.1,88,97,87.9,97,87.6",
                  },
                }),
                _c("path", {
                  staticClass: "st0",
                  attrs: {
                    id: "Fill-30",
                    d: "M48,26l0,12c0,1.6-1.3,3-3,3l-12,0L48,26z",
                  },
                }),
                _c("path", {
                  staticClass: "st2",
                  attrs: {
                    id: "Fill-31",
                    d: "M48.1,26.3c0,3.6,0,7.1,0,10.7c0,0.6,0.1,1.3-0.2,1.9c-0.5,1.1-1.5,1.5-2.6,1.5\n\t\t\t\tc-2,0-4.1,0-6.1,0l-5.5,0c0.1,0.1,0.1,0.3,0.2,0.4c4.4-4.2,8.8-8.4,\n        13.2-12.6l1.9-1.8c0.4-0.4-0.5-0.4-0.7-0.2\n\t\t\t\tc-4.4,4.2-8.8,8.4-13.2,12.6c-0.6,0.6-1.3,1.2-1.9,1.8c-0.2,0.2,0,0.4,0.2,0.4c3.9,0,7.8,\n        0,11.7,0c2.1,0,4-1.1,4-3.3\n\t\t\t\tc0-1.9,0-3.8,0-5.7c0-1.9,0-3.9,0-5.8C49,25.8,48.1,26,48.1,26.3",
                  },
                }),
                _c("path", {
                  staticClass: "st2",
                  attrs: {
                    id: "Fill-32",
                    d: "M47.2,84.2h12h19h4.4c0.2,0,0.7-0.3,0.3-0.3h-12h-19h-4.4C47.2,83.8,46.8,84.2,47.2,84.2",
                  },
                }),
                _c("path", {
                  staticClass: "st2",
                  attrs: {
                    id: "Fill-33",
                    d: "M47.2,81.2h12h19h4.4c0.2,0,0.7-0.3,0.3-0.3h-12h-19h-4.4C47.2,80.8,46.8,81.2,47.2,81.2",
                  },
                }),
                _c("path", {
                  staticClass: "st5",
                  attrs: {
                    id: "Fill-34",
                    d: "M86.7,69.6L46.5,70c-1.3,0-2.3-1-2.3-2.2L44,48.7c0-1.2,1-2.3,2.3-2.3L86.5,46\n\t\t\t\tc1.3,0,2.3,1,2.3,2.2L89,67.3C89,68.6,88,69.6,86.7,69.6",
                  },
                }),
                _c("path", {
                  staticClass: "st6",
                  attrs: {
                    id: "Fill-35",
                    d: "M85.8,69.7L48.4,70c-1.2,0-2.2-1-2.3-2.3L46,50.7c0-1.3,1-2.3,2.2-2.3L85.6,48\n\t\t\t\tc1.2,0,2.2,1,2.3,2.3L88,67.3C88,68.6,87,69.6,85.8,69.7",
                  },
                }),
                _c("g", { staticClass: "st7" }, [
                  _c("path", {
                    staticClass: "st0",
                    attrs: {
                      d: "M72,54.9l-4,4l4.3,4.3l-1.1,1.1L66.9,60L63,63.9l-1-1l3.9-3.9l-4.2-4.2l1.1-1.1l4.2,4.2l4-4L72,54.9z",
                    },
                  }),
                ]),
                _c("path", {
                  staticClass: "st2",
                  attrs: {
                    id: "Fill-36",
                    d: "M85.9,69.3c-2.8,0-5.6,0.1-8.4,0.1c-5.6,0.1-11.1,0.1-16.7,0.2c-4.1,0-8.2,0.1-12.3,0.1\n\t\t\t\tc-0.3,0-0.6,0-0.9,0c-1.5,0-2.6-1.3-2.7-2.8c-0.1-1.6,0-3.2,0-4.8c0-2.8-0.1-5.6-0.1-8.4c0-1.5-0.1-3,0-4.5\n\t\t\t\tc0.1-1.3,1-2.5,2.4-2.6c0.4,0,0.8,0,1.2,0c1.8,0,3.5,0,5.3,0c5.5-0.1,10.9-0.1,16.4-0.2c4.5,0,\n        9-0.1,13.4-0.1\n\t\t\t\tc1.2,0,2.6-0.2,3.6,0.8c1.2,1.2,0.9,3.6,0.9,5.1c0,4.7,0.1,9.4,0.1,14.2c0,1.4-0.8,2.9-2.3,2.9c-0.3,\n        0-0.7,0.3-0.2,0.3\n\t\t\t\tc1.7-0.1,3.3-1.2,3.3-3c0-0.5,0-0.9,0-1.4c0-2.6,0-5.2-0.1-7.7c0-2.5,0-5-0.1-7.5c0-0.6,\n        0-1.3-0.2-1.9c-0.4-1.3-1.7-2-3-2.1\n\t\t\t\tc-3.3-0.1-6.6,0.1-9.8,0.1c-5.8,0.1-11.7,0.1-17.5,0.2c-3.6,0-7.2,0-10.8,0.1c-2.1,0-3.6,1.3-3.6,\n        3.4c0,2.1,0,4.3,0.1,6.4\n\t\t\t\tc0,2.8,0.1,5.6,0.1,8.4c0,1.8-0.3,4.1,1.7,5c1,0.5,2.3,0.3,3.3,0.3c2,0,4,0,6-0.1c5.8-0.1,11.5-0.1,17.3-0.2\n\t\t\t\tc4.1,0,8.3-0.1,12.4-0.1c0.2,0,0.4,0,0.6,0C85.8,69.6,86.5,69.3,85.9,69.3",
                  },
                }),
              ]
            ),
          ]
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }